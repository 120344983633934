import { graphql, useStaticQuery } from "gatsby";

export interface SiteMetadata {
  siteUrl: string;
  title: string;
  enablePopup: boolean;
  hubspotPortalId: string;
}

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          title
          enablePopup
          hubspotPortalId
        }
      }
    }
  `);

  return site.siteMetadata as SiteMetadata;
};
