type StoryblokTextNode = {
  text?: string;
  type: string;
  content?: StoryblokTextNode[];
};

type StoryblokRichText = {
  type: string;
  content?: StoryblokTextNode[];
};

export const richTextToString = (richtext: StoryblokRichText): string => {
  if (!richtext?.content) return "";

  const extractText = (nodes: StoryblokTextNode[]): string => {
    return nodes
      .map((node) => {
        if (node.text) {
          return node.text;
        }
        if (node.content) {
          return extractText(node.content);
        }

        return "";
      })
      .join(" ");
  };

  return extractText(richtext.content).trim();
};
