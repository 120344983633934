import React, { FC, ReactNode } from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Link } from "gatsby";
import { useStoryblokLink } from "@app/hooks";

type Props = {
  id: string;
  children: ReactNode;
  isOpenInNewTab?: boolean;
};

export const StoryblokInternalLink: FC<Props> = ({
  id,
  children,
  isOpenInNewTab,
}) => {
  const { defaultLanguage, language } = useI18next();
  const internalLink = useStoryblokLink(
    id,
    language === defaultLanguage ? "default" : language,
  );

  return isOpenInNewTab ? (
    <a
      href={internalLink ? `/${internalLink}` : ""}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ) : (
    <Link to={internalLink ? `/${internalLink}` : ""}>{children}</Link>
  );
};
