export enum FileType {
  IMAGE = "image",
  VIDEO = "video",
  SVG = "svg",
  UNKNOWN = "unknown",
}

const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "webp"];
const videoExtensions = ["mp4", "mov", "avi", "flv", "wmv", "mkv", "webm"];
const svgExtension = "svg";

export const checkFileType = (filename: string): string => {
  const extension = filename.split(".").pop()?.toLowerCase();

  if (imageExtensions.includes(extension ?? "")) {
    return FileType.IMAGE;
  } else if (videoExtensions.includes(extension ?? "")) {
    return FileType.VIDEO;
  } else if (extension === svgExtension) {
    return FileType.SVG;
  } else {
    return FileType.UNKNOWN;
  }
};
