import React, { FC, useState } from "react";
import { Fade } from "react-awesome-reveal";
import classNames from "classnames";
import {
  BlockHeader,
  HeaderLevel,
} from "@app/components/common/block-header/BlockHeader";
import { BlockBody } from "@app/components/common/block-body/BlockBody";
import { InnerTextBlock, RichText } from "@app/components/base-page/models";
import { DEFAULT_FADE_DURATION } from "@app/constants";

type Props = {
  title: RichText | string;
  questionsPool: InnerTextBlock[];
};

export const FAQ: FC<Props> = ({ title, questionsPool }) => {
  const [active, setActive] = useState<string>("");

  return (
    <div
      className={classNames(
        "mt-20 flex flex-col gap-15 px-5",
        "lg:mx-auto lg:mt-25 lg:w-[64%] lg:gap-18 lg:px-0",
        "xl:mt-40 xl:w-[58%] xl:max-w-[977px] xl:gap-15",
      )}
    >
      <div
        className={classNames(
          "text-large text-primary-content",
          "xl:w-[62%] xl:text-xx-large",
        )}
      >
        <Fade duration={DEFAULT_FADE_DURATION} triggerOnce>
          <BlockHeader richText={title} />
        </Fade>
      </div>
      <div>
        {questionsPool.map((question) => (
          <div key={question._uid}>
            <Fade duration={DEFAULT_FADE_DURATION} triggerOnce>
              <div
                className={classNames(
                  "collapse collapse-arrow",
                  {
                    "collapse-open": active === question._uid,
                  },
                  "group rounded-none border-t border-solid border-divider py-1 text-regular",
                  "xl:py-3",
                )}
              >
                <div
                  onClick={() => {
                    setActive((prev) =>
                      prev === question._uid ? "" : question._uid,
                    );
                  }}
                  className={classNames(
                    "collapse-title !cursor-pointer",
                    "pl-0 text-regular text-primary-content",
                    "xl:text-medium",
                  )}
                >
                  <BlockHeader
                    richText={question.title}
                    headerLevel={HeaderLevel.H5}
                    className="text-base-400"
                  />
                </div>
                <div
                  className={classNames(
                    "collapse-content",
                    "px-0 text-base-400",
                    "[&>p]:mb-2.5 last:[&>p]:mb-0 [&>p]:lg:mb-5",
                    "group-has-[:checked]:pb-5",
                    "xl:group-has-[:checked]:pb-7",
                    "[&_ul>li]:pl-3 [&_ul]:pl-6",
                    "[&_ul]:list-disc [&_ul]:text-start",
                  )}
                >
                  <BlockBody richText={question.text} />
                </div>
              </div>
            </Fade>
          </div>
        ))}
      </div>
    </div>
  );
};
