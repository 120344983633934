import React, { FC } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Timeslots } from "@app/types/forms";
import { DropdownCalendar } from "./components/dropdown-calendar/DropdownCalendar";
import { TimeslotsList } from "./components/timeslots-list/TimeslotsList";

type Props = {
  timeSlots: Timeslots[];
  isDataLoading: boolean;
};

export const PickDate: FC<Props> = ({ timeSlots, isDataLoading }) => {
  const { t, i18n } = useTranslation("schedule-a-call-modal");

  return (
    <div>
      <h4
        className={classNames("mb-5 text-regular", "xl:mb-10 xl:text-medium")}
      >
        {t("date")}
      </h4>
      <DropdownCalendar
        name="date"
        label={t("date")}
        locale={i18n.language}
        datesToShow={timeSlots?.map((dayilyData) => dayilyData.date)}
        isDisabled={isDataLoading}
      />
      <h5 className={"mb-2.5 text-regular text-base-400"}>{t("time-slots")}</h5>
      <TimeslotsList
        name="timeslot"
        hint={t("select-date")}
        timeSlots={timeSlots}
        isLoading={isDataLoading}
      />
    </div>
  );
};
