import React, { FC } from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";

type Props = {
  name: string;
  label?: string;
  type?: string;
  placeholder?: string;
  isDisabled?: boolean;
};

export const Input: FC<Props> = ({
  name,
  label,
  type,
  placeholder,
  isDisabled,
}) => {
  const { register, formState } = useFormContext();

  return (
    <label className="form-control w-full">
      {label ? (
        <div className="label px-0 pb-2.5 pt-0">
          <span className="label-text text-regular text-base-400">{label}</span>
        </div>
      ) : null}
      <input
        type={type ?? "text"}
        placeholder={placeholder}
        className={classNames(
          "input input-bordered h-12 w-full bg-transparent px-4 py-2.5",
          "rounded-sm text-regular text-base-400 duration-300 placeholder:text-base-400",
          "focus:border-primary-content focus:shadow-md",
          formState.errors[name] ? "border-error" : "border-divider",
          {
            "[&:disabled]:border-divider [&:disabled]:bg-dark [&:disabled]:text-base-400 [&:disabled]:opacity-50 [&:disabled]:placeholder:text-base-400":
              isDisabled || formState.isSubmitting,
          },
        )}
        {...register(name)}
        disabled={isDisabled || formState.isSubmitting}
      />
      <div className="label relative p-0">
        {formState.errors[name] ? (
          <p
            role="alert"
            className={classNames(
              "label-text-alt absolute top-1 text-extra-small text-error",
            )}
          >
            {formState.errors[name]?.message.toString()}
          </p>
        ) : null}
      </div>
    </label>
  );
};
