export const API_PATHS = {
  INTERNAL: {
    COMPLAINT: "/api/complaint",
    CONTACT_GENERIC: "/api/contact",
    CONTACT_EXISTING_CUSTOMER: "/api/existing-client-contact",
    CONTACT_CONTEST: "/api/contest-contact",
    CONTACT_SIMPLE: "/api/simple-contact",
    LOST_CARD: "/api/lost-card",
    FINANCIAL_REPORT: "/api/report",
    SMS: "/api/sms",
  },
};

export const CORS_ALLOW_ORIGIN = process.env.CORS_ALLOW_ORIGIN;
export const CORS_ALLOW_METHODS = ["POST", "HEAD", "OPTIONS"];
