import { API_PATHS } from "@app/constants/api";
import { buildNetworkError } from "@app/helpers/api";
import { createHmacSignature } from "@app/helpers/auth";
import { GenericFormData, GenericFormMeta } from "@app/types/forms";
import { TRUE, FALSE } from "./constants";

export const useSubscribe = () => {
  const sendSubscribeRequest = async (
    data: GenericFormData,
    meta: GenericFormMeta,
  ) => {
    const { timestamp, signature } = await createHmacSignature();
    const response = await fetch(
      `${API_PATHS.INTERNAL.CONTACT_GENERIC}/${meta.formId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Timestamp": timestamp,
          "X-Signature": signature,
        },
        body: JSON.stringify({
          firstname: data.firstName,
          lastname: data.lastName,
          email: data.email,
          phoneCode: data.phoneCode.code,
          phone: data.phone,
          how_did_you_hear_about_us: data.howDidYouHear,
          i_confirm_i_am_a_swiss_tax_resident_: data.isSwissResidentConfirmed
            ? TRUE
            : FALSE,
          rt_action: meta.action,
          rt: meta.token,
          rt_version: meta.recaptchaVersion,
          location: meta.location,
          title: meta.title,
          language: meta.language,
        }),
      },
    );

    await buildNetworkError(response);
  };

  return { sendSubscribeRequest };
};
