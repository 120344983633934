export enum ButtonVariant {
  FILL_PRIMARY_CONTENT = "fillPrimaryContent",
  FILL_INFO = "fillInfo",
  FILL_PRIMARY_BLUR = "fillPrimaryBlur",
  STROKE_PRIMARY = "strokePrimary",
  OUTLINE = "outline",
}

export enum ButtonSize {
  STANDART = "standart",
  SMALL = "small",
}

export enum LinkButtonType {
  LINK = "link",
  DOWNLOAD_APP = "download_app",
  DOWNLOAD_FILE = "download_file",
  SCHEDULE_A_CALL = "schedule_a_call",
  SHEWEALTH = "shewealth",
}

export enum StoryblokLinkType {
  URL = "url",
  STORY = "story",
}
