import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 100 71"
    {...props}
  >
    <g opacity={0.846}>
      <mask
        id="a"
        width={100}
        height={71}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M0 0h99.966v70.432H0V0Z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#222321"
          fillRule="evenodd"
          d="M97.875 10.98c-1.15-4.331-4.538-7.742-8.84-8.9-7.8-2.102-39.07-2.102-39.07-2.102s-31.271 0-39.07 2.103c-4.302 1.157-7.69 4.568-8.84 8.898-2.09 7.85-2.09 24.226-2.09 24.226s0 16.376 2.09 24.226c1.15 4.33 4.538 7.74 8.84 8.898 7.799 2.103 39.07 2.103 39.07 2.103s31.27 0 39.07-2.103c4.302-1.158 7.69-4.568 8.84-8.898 2.09-7.85 2.09-24.226 2.09-24.226s0-16.377-2.09-24.226"
          clipRule="evenodd"
        />
      </g>
      <path
        fill="#FFFFFE"
        fillRule="evenodd"
        d="m39.738 50.073 26.137-14.868-26.137-14.87v29.738"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

export default SvgComponent;
