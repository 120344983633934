import React from "react";
import {
  render,
  MARK_SUBSCRIPT,
  MARK_SUPERSCRIPT,
  NODE_HEADING,
} from "storyblok-rich-text-react-renderer";
import { RichText } from "@app/components/base-page/models";
import {
  IRichText,
  IRichTextNode,
  TextType,
} from "@app/models/storyblok/basic";
import { NumericHeaderLevel } from "./types";
import { COMPONENTS_MAP, NUMERIC_HEADER_LEVEL_MAP } from "./constants";
import { addIdsToHeadings } from "./utils";

type HeadingProps = {
  level: NumericHeaderLevel;
  id?: string;
};

type ArticleContentNavigationProps = {
  content: IRichText<IRichTextNode>;
  [key: string]: unknown;
};

type Props = {
  richText: RichText | IRichText<TextType> | string;
};

export const BlockBody: React.FC<Props> = ({ richText }) => {
  if (typeof richText === "string") {
    return richText;
  }

  const updatedRichText = addIdsToHeadings(richText);

  return React.createElement(
    React.Fragment,
    null,
    render(updatedRichText, {
      markResolvers: {
        [MARK_SUBSCRIPT]: (children) =>
          React.createElement("sub", null, children),
        [MARK_SUPERSCRIPT]: (children) =>
          React.createElement("sup", null, children),
      },
      blokResolvers: {
        ["articleTable"]: (props) =>
          React.createElement(COMPONENTS_MAP["articleTable"], props),
        ["articleCustomLink"]: (props) =>
          React.createElement(COMPONENTS_MAP["articleCustomLink"], props),
        ["articleMedia"]: (props) =>
          React.createElement(COMPONENTS_MAP["articleMedia"], props),
        ["articleExcert"]: (props) =>
          React.createElement(COMPONENTS_MAP["articleExcert"], props),
        ["articleContentNavigation"]: (props) =>
          React.createElement(
            COMPONENTS_MAP[
              "articleContentNavigation"
            ] as React.FC<ArticleContentNavigationProps>,
            {
              ...props,
              content: updatedRichText,
            },
          ),
      },
      nodeResolvers: {
        [NODE_HEADING]: (children, props: HeadingProps) =>
          React.createElement(
            `h${props.level > NUMERIC_HEADER_LEVEL_MAP.h1 ? props.level : NUMERIC_HEADER_LEVEL_MAP.h2}`,
            props.id ? { id: props.id } : null,
            children,
          ),
      },
      defaultStringResolver: (children) =>
        React.createElement(React.Fragment, null, children),
    }),
  );
};
