import React, { FC } from "react";
import classNames from "classnames";
import { ITableBody } from "@app/models/storyblok/blocks/table";

type Props = {
  table: ITableBody;
};

export const Table: FC<Props> = ({ table }) => (
  <div
    className={classNames(
      "scrollbar-thumb-rounded-lg max-w-full overflow-x-auto scrollbar scrollbar-thin scrollbar-track-transparent scrollbar-thumb-base-400",
    )}
  >
    {table ? (
      <table className={classNames("text-start")}>
        {table.thead?.length && (
          <thead className={classNames("text-primary-content")}>
            <tr>
              {table.thead.map((col) => (
                <th
                  key={col._uid}
                  className={classNames(
                    "min-w-40 py-5 pr-5 align-top font-normal",
                    "text-start text-extra-small",
                    "md:py-6 md:pr-6 md:text-regular",
                    "xl:text-medium",
                    "xl:py-7 xl:pr-7",
                  )}
                >
                  {col.value}
                </th>
              ))}
            </tr>
          </thead>
        )}
        {table.tbody?.length && (
          <tbody className="text-base-400">
            {table.tbody?.map((row) => (
              <tr key={row._uid} className={"border-t border-divider"}>
                {row.body.map((col) => (
                  <td
                    key={col._uid}
                    className={classNames(
                      "min-w-40 py-5 pr-5 align-top",
                      "text-extra-small",
                      "md:py-6 md:pr-6 md:text-regular",
                      "xl:text-medium",
                      "xl:py-7 xl:pr-7",
                    )}
                  >
                    {col.value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>
    ) : null}
  </div>
);
