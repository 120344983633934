import React, { KeyboardEventHandler, FC } from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import { Link, LanguageSwitcher } from "@app/components/common";
import { useSiteData, useDownloadAppTopbar } from "@app/hooks";
import { useMenuData } from "./useMenuData";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const MainNav: FC<Props> = ({ isOpen, onClose }) => {
  const { language } = useI18next();
  const { androidLink, iosLink } = useSiteData();
  const { isVisible: isDownloadAppBarVisible } = useDownloadAppTopbar();
  const menuData = useMenuData();

  const handleOnKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  return (
    <div
      className={classNames(
        "fixed flex h-screen w-full flex-col justify-between gap-y-10",
        "bg-base-content px-5 pb-32 opacity-0 duration-500",
        "md:overflow-y-auto",
        "md:px-16 md:pb-28",
        "xl:px-24 xl:pb-24",
        isDownloadAppBarVisible
          ? "pt-[215px] md:pt-[252px] xl:pt-[205px]"
          : "pt-[143px] md:pt-[180px] xl:pt-[133px]",
        { "z-[970] opacity-100": isOpen, "z-[-999]": !isOpen },
      )}
      tabIndex={0}
      onKeyDown={handleOnKeyDown}
    >
      <nav className={"grow"}>
        <ul
          className={classNames(
            "flex flex-col gap-y-5 text-medium text-primary-content",
            "md:text-large",
            "xl:text-medium",
          )}
        >
          {menuData.map((item) => (
            <li key={item.url}>
              <Link
                to={item.url}
                onClick={onClose}
                className={classNames(
                  "duration-300",
                  "no-underline hover:text-neutral-content",
                  "[&>span]:font-bold [&>span]:italic",
                )}
                dangerouslySetInnerHTML={{
                  __html: item[language] ?? item["en"] ?? item.url,
                }}
              />
            </li>
          ))}
        </ul>
      </nav>
      <div className="md:hidden">
        <LanguageSwitcher />
      </div>
      <div className={classNames("hidden", "md:block")}>
        <div className="align-center flex flex-wrap gap-5">
          <a href={iosLink} target="_blank" rel="noopener noreferrer">
            <StaticImage
              className={classNames("w-[135px]", "md:w-[150px]")}
              src="../../../../images/app_store.png"
              alt="Apple Store"
              quality={100}
            />
          </a>

          <a href={androidLink} target="_blank" rel="noopener noreferrer">
            <StaticImage
              className={classNames("w-[153px]", "md:w-[170px]")}
              src="../../../../images/google_play.png"
              alt="Google Play"
              quality={100}
            />
          </a>
        </div>
      </div>
    </div>
  );
};
