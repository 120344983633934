import { API_PATHS } from "@app/constants/api";
import { RecaptchaVersion } from "@app/enums/recaptcha";
import { buildNetworkError } from "@app/helpers/api";
import { createHmacSignature } from "@app/helpers/auth";
import { ComplaintFormData } from "@app/types/forms";

export const useComplaint = () => {
  const sendComplaintRequest = async (
    data: ComplaintFormData,
    recaptchaToken: string,
    recaptchaAction: string,
    recaptchaVersion?: RecaptchaVersion,
  ) => {
    const { timestamp, signature } = await createHmacSignature();
    const response = await fetch(`${API_PATHS.INTERNAL.COMPLAINT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Timestamp": timestamp,
        "X-Signature": signature,
      },
      body: JSON.stringify({
        firstName: data.firstName,
        lastName: data.lastName,
        address: data.address,
        email: data.email,
        postalCode: data.postalCode,
        city: data.city,
        phone: `${data.phoneCode.code}${data.phone}`,
        service: data.service,
        company: data.company,
        message: data.message,
        location: window?.location?.href,
        rt: recaptchaToken,
        rt_action: recaptchaAction,
        rt_version: recaptchaVersion,
      }),
    });

    await buildNetworkError(response);
  };

  return { sendComplaintRequest };
};
