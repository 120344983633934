import React, { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useI18next } from "gatsby-plugin-react-i18next";
import { PhonecodeDropdown } from "@app/components/common/form/phone-input/components/phonecode-dropdown/PhonecodeDropdown";
import { ModalState } from "@app/enums/modal";
import { RecaptchaVersion } from "@app/enums/recaptcha";
import { CustomError } from "@app/enums/errors";
import { CountriesDataVariant } from "@app/enums/countries";
import { SMSLinkFormData } from "@app/types/forms";
import { useModal } from "@app/hooks";
import { RECAPTCHA_ACTION } from "../../constants";
import { useSMS } from "./useSMS";
import { useValidationSchema } from "./useValidationSchema";
import { PhoneInput } from "./components/phone-input/PhoneInput";

export const GetLinkForm: FC = () => {
  const { t } = useTranslation("download-app-modal");
  const { language } = useI18next();
  const validationSchema = useValidationSchema(t);
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      phoneCode: {
        alphaCode: "CH",
        code: "41",
      },
    },
  });
  const { sendSMSRequest } = useSMS();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { onChangeModalState } = useModal();
  const [isRecaptchaError, setIsRecaptchaError] = useState(false);

  const onSubmit = async (data: SMSLinkFormData) => {
    try {
      const recaptchaToken = await executeRecaptcha(RECAPTCHA_ACTION);

      await sendSMSRequest(
        `${data.phoneCode.code}${data.phone}`,
        language,
        recaptchaToken,
        RECAPTCHA_ACTION,
      );
      methods.reset();
      onChangeModalState(ModalState.SMS_SUCCESS);
    } catch (error) {
      if (error.name === CustomError.RECAPTCHA_NOT_ENOUGH_SCORES) {
        setIsRecaptchaError(true);

        return;
      }

      onChangeModalState(ModalState.ERROR);
      methods.reset();
    }
  };

  const onRecaptchaSubmit = async (token: string) => {
    try {
      const formData = methods.getValues() as SMSLinkFormData;

      await sendSMSRequest(
        `${formData.phoneCode.code}${formData.phone}`,
        t("sms"),
        token,
        RECAPTCHA_ACTION,
        RecaptchaVersion.V2,
      );
      onChangeModalState(ModalState.SMS_SUCCESS);
    } catch (error) {
      onChangeModalState(ModalState.ERROR);
    } finally {
      methods.reset();
      setIsRecaptchaError(false);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex w-full items-center gap-2.5 text-small text-neutral-content">
            <PhonecodeDropdown
              name="phoneCode"
              dropdownBgVariant="grey"
              vAlign="top"
              countryListVariant={
                CountriesDataVariant.SWITZERLAND_AND_NEIGHBORS
              }
            />
            <PhoneInput name="phone" placeholder={t("phone_placeholder")} />
          </div>
        </form>
      </FormProvider>
      {isRecaptchaError ? (
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-dark/75">
          <ReCAPTCHA
            sitekey={process.env.GATSBY_RECAPTCHA_V2_KEY}
            onChange={onRecaptchaSubmit}
          />
        </div>
      ) : null}
    </>
  );
};
