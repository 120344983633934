import { v4 as uuidv4 } from "uuid";
import { IRichText, IRichTextNode } from "@app/models/storyblok/basic";

const stringToKebabCase = (text: string): string => {
  const id = text
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+|-+$/g, "")
    .substring(0, 30);

  return !/^[a-z]/.test(id) ? `h-${id}` : id;
};

export const getTextFromRichText = (content?: IRichTextNode[]): string => {
  if (!content) return "";

  return content
    .map((node) => node.text || getTextFromRichText(node.content))
    .join(" ")
    .trim();
};

export const addIdsToHeadings = (richText: IRichText<IRichTextNode>) => {
  const traverseNodes = (nodes: IRichTextNode[]) => {
    if (!Array.isArray(nodes)) return;

    nodes.forEach((node) => {
      if (node.type === "heading" && node.attrs) {
        const text = getTextFromRichText(node.content);

        node.attrs.id = text ? stringToKebabCase(text) : uuidv4();
      }
      if (node.content) {
        traverseNodes(node.content);
      }
      if (node.attrs && node.attrs.body) {
        traverseNodes(node.attrs.body);
      }
    });
  };

  traverseNodes(richText.content);

  return richText;
};

export const extractHeadings = (
  richText: IRichText<IRichTextNode>,
): IRichTextNode[] => {
  const headings: IRichTextNode[] = [];

  const traverseAndCollect = (nodes: IRichTextNode[]) => {
    if (!Array.isArray(nodes)) return;

    nodes.forEach((node) => {
      if (node.type === "heading") {
        headings.push(node);
      }
      if (node.content) {
        traverseAndCollect(node.content);
      }
      if (node.attrs && node.attrs.body) {
        traverseAndCollect(node.attrs.body);
      }
    });
  };

  traverseAndCollect(richText.content);

  return headings;
};
