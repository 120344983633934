import React, { FC, useRef, useState, useEffect } from "react";
import dayjs from "dayjs";
import classNames from "classnames";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { Close } from "@app/components/common/icons";
import { Dialog } from "@app/constants/ui";
import { CustomTrackingEvent } from "@app/constants/tracking";
import { ModalState } from "@app/enums/modal";
import { RecaptchaVersion } from "@app/enums/recaptcha";
import { CustomError } from "@app/enums/errors";
import { ScheduleACallFormData, Timeslots, Service } from "@app/types/forms";
import { useOutsideClick, useModal } from "@app/hooks";
import { getTimeslots } from "@app/helpers";
import { formatToDateTimeString } from "@app/formatters";
import { Button } from "../../../button/Button";
import {
  DEFAULT_LANGUAGE,
  FORM_IDS,
  RECAPTCHA_BUSINESSES_ACTION,
  RECAPTCHA_STAFF_ACTION,
  RECAPTCHA_APPOINTMENT_ACTION,
} from "./constants";
import { useBooking } from "./useBooking";
import { useValidationSchema } from "./useValidationSchema";
import { PickService } from "./components/pick-service/PickService";
import { PickDate } from "./components/pick-date/PickDate";
import { Details } from "./components/details/Details";
import { Additional } from "./components/additional/Additional";

export const ScheduleACall: FC = () => {
  const { t, i18n } = useTranslation("schedule-a-call-modal");
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isRecaptchaError, setIsRecaptchaError] = useState(false);
  const [services, setServices] = useState<Service[]>();
  const [timeSlots, setTimeSlots] = useState<Timeslots[]>();
  const { onChangeModalState } = useModal();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const ref = useRef<HTMLDivElement>(null);
  const validationSchema = useValidationSchema(t);
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      phoneCode: {
        alphaCode: "CH",
        code: "41",
      },
    },
  });
  const { getBusinesses, getStaffAvailability, bookAppointment } = useBooking();

  useEffect(() => {
    const controller = new AbortController();

    if (executeRecaptcha) {
      setIsDataLoading(true);
      executeRecaptcha(RECAPTCHA_BUSINESSES_ACTION)
        .then(() => {
          getBusinesses(controller)
            .then((businesses) => {
              setServices(businesses);
              setIsDataLoading(false);
            })
            .catch((error) => {
              if (error.name !== "AbortError") {
                onChangeModalState(ModalState.ERROR);
              }
            });
        })
        .catch(() => {
          onChangeModalState(ModalState.ERROR);
        });

      return () => {
        controller.abort();
      };
    }
  }, [executeRecaptcha]);

  useOutsideClick(ref, () => onChangeModalState(ModalState.IDLE));

  const onChangeService = async (service: Service) => {
    setIsDataLoading(true);
    setTimeSlots([]);
    getStaffAvailability(
      formatToDateTimeString(new Date()),
      service.staffMemberIds,
    )
      .then((data) => {
        const newSlots: Timeslots[] = data
          ?.map((dailyData) => {
            return {
              date: dailyData.date,
              slots: getTimeslots(dailyData.data, service),
            };
          })
          .filter((dailySlots) => dailySlots.slots.length);

        setTimeSlots(newSlots);
        methods.setValue(
          "date",
          newSlots.length
            ? dayjs(
                newSlots.sort((a, b) =>
                  dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1,
                )[0].date,
              ).toDate()
            : undefined,
        );
        methods.setValue("timeslot", undefined);
        setIsDataLoading(false);
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          onChangeModalState(ModalState.ERROR);
        }
      });
  };

  const onSubmit = async (data: ScheduleACallFormData) => {
    try {
      const staffToken = await executeRecaptcha(RECAPTCHA_STAFF_ACTION);
      const appointmentToken = await executeRecaptcha(
        RECAPTCHA_APPOINTMENT_ACTION,
      );

      setIsDataLoading(true);

      await bookAppointment(
        FORM_IDS[i18n.language] ?? FORM_IDS[DEFAULT_LANGUAGE],
        i18n.language,
        window?.location?.href ?? "",
        document?.title ?? "",
        [
          {
            token: staffToken,
            action: RECAPTCHA_STAFF_ACTION,
          },
          {
            token: appointmentToken,
            action: RECAPTCHA_APPOINTMENT_ACTION,
          },
        ],
        data,
      );
      onChangeModalState(ModalState.SCHEDULE_A_CALL_SUCCESS);
    } catch (error) {
      if (error.name === CustomError.RECAPTCHA_NOT_ENOUGH_SCORES) {
        setIsRecaptchaError(true);

        return;
      }

      onChangeModalState(ModalState.ERROR);
    }
  };

  const onRecaptchaSubmit = async (token: string) => {
    try {
      const formData = methods.getValues() as ScheduleACallFormData;
      const staffToken = await executeRecaptcha(RECAPTCHA_STAFF_ACTION);

      await bookAppointment(
        FORM_IDS[i18n.language] ?? FORM_IDS[DEFAULT_LANGUAGE],
        i18n.language,
        window?.location?.href ?? "",
        document?.title ?? "",
        [
          {
            token: staffToken,
            action: RECAPTCHA_STAFF_ACTION,
          },
          {
            token: token,
            version: RecaptchaVersion.V2,
          },
        ],
        formData,
      );
      onChangeModalState(ModalState.SCHEDULE_A_CALL_SUCCESS);
    } catch (error) {
      onChangeModalState(ModalState.ERROR);
    }
  };

  return (
    <dialog id={Dialog.SCHEDULE_A_CALL} className="modal modal-open z-[1000]">
      <div
        className={classNames(
          "modal-box relative max-h-screen w-screen overflow-hidden rounded-none bg-dark py-2 pl-0 pr-1 text-primary-content",
          "md:max-h-[calc(100vh-2em)] md:rounded-lg md:py-4",
          "xl:w-full xl:max-w-[900px]",
        )}
        ref={ref}
      >
        <div
          className={classNames(
            "scrollbar-thumb-rounded flex h-[calc(100vh-1em)] flex-col overflow-auto py-24 pl-5 pr-4 scrollbar scrollbar-thin scrollbar-track-transparent scrollbar-thumb-base-400",
            "md:h-[calc(100vh-6em)] md:py-20 md:pl-10 md:pr-9",
            "xl:py-15 xl:pl-20 xl:pr-[76px]",
          )}
        >
          <h3
            className={classNames(
              "mb-5 text-large",
              "xl:mb-2.5 xl:self-center xl:text-large",
            )}
          >
            {t("title")}
          </h3>
          <p
            className={classNames(
              "mb-15 text-regular text-base-400",
              "xl:self-center xl:text-medium",
            )}
          >
            {t("subtitle")}
          </p>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              className="w-full space-y-15"
            >
              <PickService
                name="service"
                label={t("select-service")}
                services={services}
                onChangeService={onChangeService}
                isDataLoading={isDataLoading}
              />
              <PickDate timeSlots={timeSlots} isDataLoading={isDataLoading} />
              <Details isDataLoading={isDataLoading} />
              <Additional isDataLoading={isDataLoading} />
              <div className="flex justify-center">
                <Button
                  label={t("submit")}
                  type="submit"
                  isLoading={isDataLoading}
                  isDisabled={isDataLoading}
                  trackEvent={CustomTrackingEvent.BOOK_APPOINTMENT}
                />
              </div>
            </form>
          </FormProvider>
        </div>

        <Close
          className={classNames(
            "absolute right-5 top-5 h-5 w-5 cursor-pointer duration-300",
            "lg:right-6 lg:top-6 lg:h-6 lg:w-6",
            "hover:opacity-75",
          )}
          onClick={() => onChangeModalState(ModalState.IDLE)}
        />
        {isRecaptchaError ? (
          <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-dark/75">
            <ReCAPTCHA
              sitekey={process.env.GATSBY_RECAPTCHA_V2_KEY}
              onChange={onRecaptchaSubmit}
            />
          </div>
        ) : null}
      </div>
    </dialog>
  );
};
