/* eslint-disable no-console */
import scrollToElement from "scroll-to-element";

const errorTypes = {
  IMPROPPER_FORMATTING:
    "Anchor path should contain an absolute root path `/` and selector `#` Ex: `/about#team`",
  INVALID_HASH:
    "Anchor Links plugin attempted to scroll to an invalid hash on route change.",
};

const SCROLL_OFFSET = -80;

export const isBrowser = typeof window !== "undefined";

export const isDevelopment = process.env.NODE_ENV !== "production";

export function logWarning(message) {
  if (isDevelopment) console.warn(message);
}

export function scroller(target, offset = 0, duration = 1000) {
  setTimeout(() => {
    scrollToElement(target, {
      duration,
      offset,
    });
  }, 100);
}

export function handleLinkClick(to, e, isSamePage, onAnchorLinkClick) {
  /**
   * Log warnings on click
   */
  const improperFormatting = !to.includes("/") || !to.includes("#");

  if (improperFormatting) logWarning(errorTypes.IMPROPPER_FORMATTING);

  if (isBrowser && to.includes("#")) {
    const [, anchor] = to.split("#");

    if (isSamePage) {
      e.preventDefault();
      scroller(`#${anchor}`, SCROLL_OFFSET, window.gatsby_scroll_duration);
    }
  }

  if (onAnchorLinkClick) onAnchorLinkClick();
}

export function handleStrippedLinkClick(to, e, isSamePage, onAnchorLinkClick) {
  /**
   * Log warnings on click
   */
  const improperFormatting = !to.includes("/") || !to.includes("#");

  if (improperFormatting) logWarning(errorTypes.IMPROPPER_FORMATTING);

  const [, anchor] = to.split("#");

  /**
   * Determine location, run scroller or set window variable
   */

  if (isSamePage) {
    e.preventDefault();
    scroller(`#${anchor}`, SCROLL_OFFSET, window.gatsby_scroll_duration);
  } else {
    window.gatsby_scroll_hash = `#${anchor}`;
  }

  if (onAnchorLinkClick) onAnchorLinkClick();
}

export function stripHashedLocation(to) {
  return to.split("#")[0];
}

export function checkHash(location, offset) {
  const { hash } = location,
    selector = hash ? hash.substr(1) : null,
    validElement = selector ? document.getElementById(selector) : null;

  if (hash && Boolean(validElement)) scroller(hash, offset);
  else if (hash && selector && !validElement)
    logWarning(errorTypes.INVALID_HASH);
}
