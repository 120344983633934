import React, { FC, useState, useRef } from "react";
import classNames from "classnames";
import loadable from "@loadable/component";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImage } from "gatsby-plugin-storyblok-image";
import { Play } from "@app/components/common/icons";
import { FileType, checkFileType } from "@app/helpers/check-file-type";
import { IAsset } from "@app/models/storyblok/basic";

const ReactPlayer = loadable(() => import("react-player"));

type Props = {
  media?: IAsset;
  youtube?: string;
};

export const Media: FC<Props> = ({ media, youtube }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef(null);

  return (
    <div className={classNames("my-15 w-full")}>
      {youtube || checkFileType(media.filename) === FileType.VIDEO ? (
        <div
          className={classNames("relative w-full overflow-hidden rounded-lg", {
            "aspect-video": youtube,
          })}
        >
          <ReactPlayer
            ref={playerRef}
            url={youtube ?? media.filename}
            width="100%"
            height="100%"
            playing={isPlaying}
            controls={youtube ? true : isPlaying}
            config={{
              file: {
                attributes: { disablePictureInPicture: true },
              },
            }}
            className={classNames({
              "[&>video]:aspect-video [&>video]:rounded-lg": !youtube,
            })}
          />
          {!isPlaying && !youtube && (
            <div
              className="bg-black/50 absolute inset-0 flex cursor-pointer items-center justify-center"
              onClick={() => setIsPlaying(true)}
            >
              <Play className={classNames("w-20", "lg:w-25")} />
            </div>
          )}
        </div>
      ) : (
        <GatsbyImage
          image={getGatsbyImage(media.filename, { layout: "fullWidth" })}
          alt={media.alt}
          className={classNames(
            "aspect-[3/2] w-full rounded-lg",
            "md:aspect-video",
          )}
        />
      )}
    </div>
  );
};
