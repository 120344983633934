import { ShewealthContactFormData } from "./types";

export const INITIAL_VALUES: ShewealthContactFormData = {
  firstName: "",
  lastName: "",
  email: "",
  isSwissResidentConfirmed: false,
  isPrivacyPolicyConfirmed: false,
};

export const RECAPTCHA_ACTION = "shewealth_contact";
export const UNDEFINED_VALUE = "undefined";
