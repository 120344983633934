import React, { useState, useRef } from "react";
import classNames from "classnames";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useOutsideClick } from "@app/hooks";
import gb from "@app/images/flags/gb.svg";
import de from "@app/images/flags/de.svg";
import fr from "@app/images/flags/fr.svg";
import it from "@app/images/flags/it.svg";
import { Globe } from "../icons";

const LANGUAGE_LABELS = {
  EN: {
    label: "English",
    icon: gb,
  },
  DE: {
    label: "Deutsch",
    icon: de,
  },
  FR: {
    label: "Français",
    icon: fr,
  },
  IT: {
    label: "Italiano",
    icon: it,
  },
};

export const LanguageSwitcher = () => {
  const { languages, language, originalPath, changeLanguage } = useI18next();
  const [isOpen, setIsOpen] = useState(false);
  const mobileDropdownRef = useRef(null);

  useOutsideClick(mobileDropdownRef, () => setIsOpen(false));

  return (
    <>
      <div
        className={classNames("dropdown hidden text-extra-small", "md:block")}
      >
        <div
          tabIndex={0}
          role="button"
          className={classNames(
            "flex items-center gap-x-3 text-primary-content",
          )}
        >
          <Globe className="h-[22px] w-[22px] text-primary-content" />
          <span>{LANGUAGE_LABELS[language.toUpperCase()].label}</span>
        </div>
        <ul
          tabIndex={0}
          className="menu dropdown-content z-[1] mt-2 flex flex-col gap-y-1 rounded-sm bg-primary-content px-4 py-2 text-primary shadow"
        >
          {languages
            .filter((lang) => lang !== language)
            .map((lang) => (
              <li
                key={lang}
                onClick={() => {
                  changeLanguage(lang, originalPath);
                  if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur();
                  }
                }}
              >
                <span className={classNames("p-0 hover:bg-transparent")}>
                  {LANGUAGE_LABELS[lang.toUpperCase()].label}
                </span>
              </li>
            ))}
        </ul>
      </div>
      <div
        role="button"
        className={classNames(
          "flex items-center gap-x-3 text-primary-content",
          "md:hidden",
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Globe className="h-[22px] w-[22px] text-primary-content" />
        <span>{LANGUAGE_LABELS[language.toUpperCase()].label}</span>
      </div>
      <ul
        className={classNames(
          "absolute bottom-0 left-0 right-0 flex flex-col gap-y-5 rounded-t-lg px-5 pb-32 pt-20",
          "bg-dark text-regular text-primary-content duration-300",
          "md:hidden",
          isOpen ? "translate-y-0" : "translate-y-full",
        )}
        ref={mobileDropdownRef}
      >
        {languages
          .filter((lang) => lang !== language)
          .map((lang) => (
            <li
              key={lang}
              onClick={() => {
                changeLanguage(lang, originalPath);
                setIsOpen(!isOpen);
              }}
            >
              <div className="flex items-center gap-x-5 p-0">
                <img
                  src={LANGUAGE_LABELS[lang.toUpperCase()].icon}
                  alt={`${LANGUAGE_LABELS[lang.toUpperCase()].label} icon`}
                  className="h-5 w-5 rounded-full"
                />
                <span>{LANGUAGE_LABELS[lang.toUpperCase()].label}</span>
              </div>
            </li>
          ))}
      </ul>
    </>
  );
};
