import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { IArticle } from "@app/models/storyblok/blocks/article";
import { richTextToString } from "@app/utils/storyblok";
import { PostSummary } from "@app/models";

export const useBlogPosts = (): PostSummary[] => {
  const { language } = useI18next();
  const { postsData, articlesData } = useStaticQuery(graphql`
    {
      postsData: allStoryblokEntry(
        filter: {
          full_slug: { regex: "/blog/" }
          field_component: { eq: "post" }
          published_at: { ne: "null" }
        }
      ) {
        edges {
          node {
            uuid
            slug
            fullSlug: full_slug
            lang
            categoryId: field_categoryName_string
            date: field_date_string
            title: field_title_string
            image: field_image_string
            excerpt: field_excerpt_string
            isIndexingDisabled: field_isIndexingDisabled_boolean
          }
        }
      }
      articlesData: allStoryblokEntry(
        filter: {
          full_slug: { regex: "/blog/" }
          field_component: { eq: "articlePage" }
          published_at: { ne: null }
        }
      ) {
        edges {
          node {
            uuid
            slug
            fullSlug: full_slug
            lang
            categoryId: field_category_string
            date: field_date_string
            title: field_title_string
            isIndexingDisabled: field_isIndexingDisabled_boolean
            content
          }
        }
      }
    }
  `);

  const articles = articlesData.edges
    .filter((article) => {
      const lang = article.node.lang === "default" ? "en" : article.node.lang;

      return lang === language;
    })
    .map((article) => {
      const parsedContent: IArticle = JSON.parse(article.node.content);

      return {
        uuid: article.node.uuid,
        slug: article.node.slug,
        lang: article.node.lang,
        categoryId: article.node.categoryId,
        date: article.node.date,
        isIndexingDisabled: article.node.isIndexingDisabled,
        fullSlug: article.node.fullSlug.toLowerCase(),
        image: parsedContent.image?.filename,
        title: richTextToString(parsedContent.header),
      };
    });
  const posts = postsData.edges
    .filter((post) => {
      const lang = post.node.lang === "default" ? "en" : post.node.lang;

      return lang === language;
    })
    .map((post) => ({
      ...post.node,
      fullSlug: post.node.fullSlug.toLowerCase(),
    }));

  const allArticles = [...posts, ...articles]
    .filter((post) => !post.isIndexingDisabled)
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  return allArticles;
};
