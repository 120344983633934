import React, { FC } from "react";
import classNames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImage } from "gatsby-plugin-storyblok-image";
import { LinkButton } from "@app/components/common/link-button/LinkButton";
import {
  IAsset,
  IButton,
  IRichText,
  TextType,
} from "@app/models/storyblok/basic";
import { BlockBody } from "../../BlockBody";

type Props = {
  text: IRichText<TextType>;
  link: IButton[];
  backgroundImage?: IAsset;
};

export const CustomLink: FC<Props> = ({ text, link, backgroundImage }) => (
  <div
    className={classNames(
      "relative my-15 overflow-hidden rounded-lg border border-divider px-5 py-10 text-center text-medium text-primary-content",
      "md:px-14 md:py-12",
    )}
  >
    {backgroundImage?.filename ? (
      <div className="absolute bottom-0 left-0 right-0 top-0 rounded-lg">
        <GatsbyImage
          image={getGatsbyImage(backgroundImage.filename, {
            layout: "fullWidth",
          })}
          alt={backgroundImage.alt ?? "Background"}
          className={classNames("h-full w-full rounded-lg opacity-50")}
        />
      </div>
    ) : null}
    <div className="relative z-10 [&_p]:my-0">
      <div className={classNames("mb-5", "md:mb-7")}>
        <BlockBody richText={text} />
      </div>

      {link?.length ? (
        <LinkButton
          to={link[0].to.url || link[0].to.id}
          label={link[0].title}
          variant={link[0].variant}
          type={link[0].type}
          storyblokType={link[0].to.linktype}
          fileRef={link[0].file?.filename}
          fileName={link[0].file?.filename?.split("/").pop()}
          isShowOnMobileApp={link[0].isShowOnMobileApp}
        />
      ) : null}
    </div>
  </div>
);
