import React, { FC } from "react";
import classNames from "classnames";
import { IRichText, TextType } from "@app/models/storyblok/basic";
import { BlockBody } from "../../BlockBody";

type Props = {
  text: IRichText<TextType>;
};

export const Excert: FC<Props> = ({ text }) => (
  <div
    className={classNames(
      "my-15 w-full text-center text-medium text-primary-content",
      "md:mx-auto md:max-w-[600px]",
      "lg:text-large",
    )}
  >
    <BlockBody richText={text} />
  </div>
);
