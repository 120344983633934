import { API_PATHS } from "@app/constants/api";
import { RecaptchaVersion } from "@app/enums/recaptcha";
import { buildNetworkError } from "@app/helpers/api";
import { createHmacSignature } from "@app/helpers/auth";

export const useSMS = () => {
  const sendSMSRequest = async (
    phone: string,
    locale: string,
    recaptchaToken: string,
    recaptchaAction: string,
    recaptchaVersion?: RecaptchaVersion,
  ) => {
    const { timestamp, signature } = await createHmacSignature();
    const response = await fetch(`${API_PATHS.INTERNAL.SMS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Timestamp": timestamp,
        "X-Signature": signature,
      },
      body: JSON.stringify({
        phone,
        locale,
        rt_action: recaptchaAction,
        rt: recaptchaToken,
        rt_version: recaptchaVersion,
      }),
    });

    await buildNetworkError(response);
  };

  return { sendSMSRequest };
};
