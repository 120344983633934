import React, { FC } from "react";
import classNames from "classnames";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomTrackingEvent } from "@app/constants/tracking";
import { useIsXLMediaQuery } from "@app/hooks";
import { ButtonVariant, ButtonSize } from "../enums";
import { Spinner } from "../icons";

type Props = {
  label: string;
  variant?: ButtonVariant;
  type?: "button" | "submit" | "reset";
  size?: ButtonSize;
  isFullWidth?: boolean;
  labelStyles?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  trackEvent?: CustomTrackingEvent;
  onClick?: () => void;
};

export const Button: FC<Props> = ({
  label,
  variant,
  type = "button",
  size = ButtonSize.STANDART,
  isFullWidth,
  labelStyles,
  isDisabled,
  isLoading,
  trackEvent,
  onClick,
}) => {
  const handleClick = () => {
    if (trackEvent && window) {
      window.dataLayer.push({ event: trackEvent });
    }

    if (onClick) {
      onClick();
    }
  };

  const isXLScreen = useIsXLMediaQuery();
  const buttonVariant = isXLScreen
    ? variant
    : variant === ButtonVariant.FILL_PRIMARY_BLUR
      ? ButtonVariant.FILL_PRIMARY_CONTENT
      : variant;

  return (
    <button
      className={classNames(
        "inline-flex h-auto min-h-0 items-center gap-3 no-underline duration-300",
        {
          "rounded-full": buttonVariant !== ButtonVariant.OUTLINE,
        },
        {
          "px-7 py-3":
            buttonVariant !== ButtonVariant.OUTLINE &&
            buttonVariant !== ButtonVariant.STROKE_PRIMARY &&
            size === ButtonSize.STANDART,
        },
        {
          "px-7 py-2":
            buttonVariant !== ButtonVariant.OUTLINE &&
            buttonVariant !== ButtonVariant.STROKE_PRIMARY &&
            size === ButtonSize.SMALL,
        },
        {
          "border-none text-base-content":
            !buttonVariant ||
            buttonVariant === ButtonVariant.FILL_PRIMARY_CONTENT ||
            buttonVariant === ButtonVariant.FILL_INFO,
        },
        {
          "bg-primary-content text-base-content hover:enabled:bg-primary-content/[.85]":
            buttonVariant !== ButtonVariant.FILL_INFO &&
            buttonVariant !== ButtonVariant.FILL_PRIMARY_BLUR &&
            buttonVariant !== ButtonVariant.STROKE_PRIMARY &&
            buttonVariant !== ButtonVariant.OUTLINE,
        },
        {
          "bg-info hover:enabled:bg-info/[.85]":
            buttonVariant === ButtonVariant.FILL_INFO,
        },
        {
          "border border-primary-content bg-transparent text-primary-content hover:enabled:bg-primary-content/[.4]":
            buttonVariant === ButtonVariant.STROKE_PRIMARY,
        },
        {
          "border-none bg-primary-content/30 text-primary-content backdrop-blur-sm hover:enabled:bg-primary-content/40":
            buttonVariant === ButtonVariant.FILL_PRIMARY_BLUR,
        },
        {
          "justify-between rounded-sm border border-divider px-5 py-2 text-neutral-content hover:enabled:border-primary-content hover:enabled:bg-transparent hover:enabled:text-neutral-content":
            buttonVariant === ButtonVariant.OUTLINE,
        },
        {
          "px-[27px]": buttonVariant === ButtonVariant.STROKE_PRIMARY,
          "py-[7px]":
            buttonVariant === ButtonVariant.STROKE_PRIMARY &&
            size === ButtonSize.SMALL,
          "py-[11px]":
            buttonVariant === ButtonVariant.STROKE_PRIMARY &&
            size !== ButtonSize.SMALL,
        },
        { "w-full": isFullWidth },
        { "cursor-not-allowed opacity-[.85]": isDisabled || isLoading },
      )}
      disabled={isDisabled || isLoading}
      type={type}
      onClick={handleClick}
    >
      <span className={classNames("text-small-tall", labelStyles)}>
        {label}
      </span>
      {buttonVariant === ButtonVariant.OUTLINE && !isLoading ? (
        <FontAwesomeIcon
          icon={faChevronRight}
          width={8}
          className={"text-primary-content"}
        />
      ) : null}
      {isLoading ? (
        <Spinner
          className={classNames("h-4 w-4", {
            "stroke-primary-content":
              buttonVariant === ButtonVariant.STROKE_PRIMARY ||
              buttonVariant === ButtonVariant.FILL_PRIMARY_BLUR ||
              buttonVariant === ButtonVariant.OUTLINE,
            "stroke-base-content":
              !buttonVariant ||
              buttonVariant === ButtonVariant.FILL_INFO ||
              buttonVariant === ButtonVariant.FILL_PRIMARY_CONTENT,
          })}
        />
      ) : null}
    </button>
  );
};
