import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Dialog } from "@app/constants/ui";
import { truncateString } from "@app/utils";
import { CookiesUsage } from "./components/CookiesUsage/CookiesUsage";
import { useCookiesPolicy } from "./useCookiesPolicy";

export const CookieSettingsModal: FC = () => {
  const { t } = useTranslation("cookiesettings_modal");
  const [showMore, setShowMore] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const policy = useCookiesPolicy();

  const { html, necessary, non_necessary } = policy;

  const toggleShowMore = () => setShowMore(!showMore);

  const close = () => {
    (
      document.getElementById(Dialog.COOKIES_SETTINGS) as HTMLDialogElement
    ).close();
  };

  return (
    <dialog id={Dialog.COOKIES_SETTINGS} className="modal">
      <div
        className={classNames(
          "modal-box flex min-h-full w-auto max-w-full flex-col items-center justify-between gap-y-5 rounded-sm bg-[#111] px-5 py-12 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-base-400",
          "md:min-h-0 md:max-w-[90%] md:p-12",
          "lg:max-w-[800px]",
        )}
      >
        <div>
          <form method="dialog">
            <button
              className={classNames(
                "btn btn-ghost absolute right-2 top-2 text-regular text-grey-neutral",
                "md:hidden",
              )}
            >
              ✕
            </button>
          </form>
          <div
            className="prose text-regular text-grey-neutral prose-a:break-all prose-a:text-grey-neutral"
            dangerouslySetInnerHTML={{
              __html: showMore ? html : truncateString(policy.html, 300),
            }}
          />
          <div>
            <button
              className="fs-md p-0 pt-2 text-regular text-grey-neutral underline"
              onClick={toggleShowMore}
            >
              {showMore ? t("show_less") : t("show_more")}
            </button>
          </div>

          <CookiesUsage
            necessary={necessary}
            nonNecessary={non_necessary}
            isNotNecessaryEnabled={enabled}
            toggleNotNecessary={setEnabled}
          />
        </div>

        <button
          className={classNames(
            "rounded-3xl bg-green px-4 py-2 text-extra-small text-white",
            "md:self-end md:px-7 md:py-3",
          )}
          onClick={close}
        >
          {t("accept_button")}
        </button>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};
