import { Table } from "./components/table/Table";
import { CustomLink } from "./components/custom-link/CustomLink";
import { Media } from "./components/media/Media";
import { Excert } from "./components/excert/Excert";
import { ContentNavigation } from "./components/content-navigation/ContentNavigation";

export const COMPONENTS_MAP: Record<string, React.FC> = {
  articleTable: Table,
  articleCustomLink: CustomLink,
  articleMedia: Media,
  articleExcert: Excert,
  articleContentNavigation: ContentNavigation,
};

export const NUMERIC_HEADER_LEVEL_MAP = {
  h1: 1,
  h2: 2,
  h3: 3,
  h4: 4,
  h5: 5,
  h6: 6,
};
