import { UAParser } from "ua-parser-js";

export const truncateString = (str: string, num: number) => {
  return str.length > num ? str.slice(0, num > 3 ? num - 3 : num) + "..." : str;
};

export const isBrowser = () => {
  return typeof window !== `undefined`;
};

export const isServer = () => {
  return typeof window === `undefined`;
};

export const isExternalURL = (url: string) => {
  const domain = (url: string) =>
    url.replace("http://", "").replace("https://", "").split("/")[0];
  const regex = new RegExp("^https?://");

  if (!regex.test(url)) {
    return false;
  }

  return domain(location.href) !== domain(url);
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getLastDayOfMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const formatCurrency = (
  amount: number,
  hideCurrencySymbol?: boolean,
) => {
  const value = hideCurrencySymbol ? "" : "CHF ";

  if (!amount) return `${value} 0`;

  return (
    value + (amount || 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'")
  );
};

export const getCurrentUrl = (siteUrl: string, path: string) =>
  new URL(path, siteUrl).href;

export const getUserOSName = () => {
  const parser = new UAParser();

  return parser.getOS().name;
};

export const hasQueryParams = (url: string) => /\?.+=/.test(url);
