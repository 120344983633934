import React, { FC } from "react";
import { Link } from "@app/components/common/link/Link";
import { CustomTrackingEvent } from "@app/constants/tracking";
import { ModalState } from "@app/enums/modal";
import { useViewVariant } from "@app/hooks/use-view-variant/useViewVariant";
import { OsNames } from "@app/constants";
import { useSiteData, useModal } from "@app/hooks";
import {
  ButtonVariant,
  LinkButtonType,
  StoryblokLinkType,
  ButtonSize,
} from "../enums";
import { Button } from "../button/Button";
import { StoryblokInternalLink } from "./../storyblok-internal-link/StoryblokInternalLink";
import { useOSName } from "./useOSName";

type Props = {
  label: string;
  to?: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  type?: LinkButtonType;
  fileRef?: string;
  fileName?: string;
  isFullWidth?: boolean;
  labelStyles?: string;
  storyblokType?: StoryblokLinkType;
  isShowOnMobileApp?: boolean;
  isOpenInNewTab?: boolean;
  onClick?: () => void;
};

export const LinkButton: FC<Props> = ({
  label,
  to,
  variant,
  size,
  type,
  fileRef,
  fileName,
  isFullWidth,
  labelStyles,
  storyblokType,
  isShowOnMobileApp,
  isOpenInNewTab,
}) => {
  const { androidLink, iosLink } = useSiteData();
  const { onChangeModalState } = useModal();
  const { isContentOnly } = useViewVariant();
  const osName = useOSName();

  return (
    <>
      {isContentOnly && !isShowOnMobileApp ? null : type ===
        LinkButtonType.DOWNLOAD_FILE ? (
        <a
          href={fileRef ?? to}
          download={fileName ?? null}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button {...{ variant, label, size, isFullWidth, labelStyles }} />
        </a>
      ) : type === LinkButtonType.DOWNLOAD_APP ? (
        osName === OsNames.Android || osName === OsNames.IOs ? (
          <a
            href={osName === OsNames.Android ? androidLink : iosLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              trackEvent={CustomTrackingEvent.DOWNLOAD_APP}
              {...{ variant, label, size, isFullWidth, labelStyles }}
            />
          </a>
        ) : (
          <Button
            trackEvent={CustomTrackingEvent.DOWNLOAD_APP}
            onClick={() => onChangeModalState(ModalState.DOWNLOAD_APP)}
            {...{ variant, label, size, isFullWidth, labelStyles }}
          />
        )
      ) : type === LinkButtonType.SCHEDULE_A_CALL ? (
        <Button
          onClick={() => onChangeModalState(ModalState.SCHEDULE_A_CALL)}
          {...{ variant, label, size, isFullWidth, labelStyles }}
        />
      ) : type === LinkButtonType.SHEWEALTH ? (
        <Button
          onClick={() => onChangeModalState(ModalState.SHEWEALTH)}
          {...{ variant, label, size, isFullWidth, labelStyles }}
        />
      ) : storyblokType === StoryblokLinkType.STORY ? (
        <StoryblokInternalLink id={to} {...{ isOpenInNewTab }}>
          <Button {...{ variant, label, size, isFullWidth, labelStyles }} />
        </StoryblokInternalLink>
      ) : (
        <Link to={to ?? ""} {...{ isOpenInNewTab }}>
          <Button {...{ variant, label, size, isFullWidth, labelStyles }} />
        </Link>
      )}
    </>
  );
};
