exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-bank-apple-pay-index-tsx": () => import("./../../../src/pages/bank/apple-pay/index.tsx" /* webpackChunkName: "component---src-pages-bank-apple-pay-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-ombudsman-index-tsx": () => import("./../../../src/pages/contact/ombudsman/index.tsx" /* webpackChunkName: "component---src-pages-contact-ombudsman-index-tsx" */),
  "component---src-pages-de-404-tsx": () => import("./../../../src/pages/de/404.tsx" /* webpackChunkName: "component---src-pages-de-404-tsx" */),
  "component---src-pages-events-index-tsx": () => import("./../../../src/pages/events/index.tsx" /* webpackChunkName: "component---src-pages-events-index-tsx" */),
  "component---src-pages-financial-reports-index-tsx": () => import("./../../../src/pages/financial-reports/index.tsx" /* webpackChunkName: "component---src-pages-financial-reports-index-tsx" */),
  "component---src-pages-fr-404-tsx": () => import("./../../../src/pages/fr/404.tsx" /* webpackChunkName: "component---src-pages-fr-404-tsx" */),
  "component---src-pages-get-app-index-tsx": () => import("./../../../src/pages/get-app/index.tsx" /* webpackChunkName: "component---src-pages-get-app-index-tsx" */),
  "component---src-pages-investment-cost-calculator-index-tsx": () => import("./../../../src/pages/investment/cost-calculator/index.tsx" /* webpackChunkName: "component---src-pages-investment-cost-calculator-index-tsx" */),
  "component---src-pages-it-404-tsx": () => import("./../../../src/pages/it/404.tsx" /* webpackChunkName: "component---src-pages-it-404-tsx" */),
  "component---src-pages-legal-index-tsx": () => import("./../../../src/pages/legal/index.tsx" /* webpackChunkName: "component---src-pages-legal-index-tsx" */),
  "component---src-pages-lost-card-index-tsx": () => import("./../../../src/pages/lost-card/index.tsx" /* webpackChunkName: "component---src-pages-lost-card-index-tsx" */),
  "component---src-pages-perks-index-tsx": () => import("./../../../src/pages/perks/index.tsx" /* webpackChunkName: "component---src-pages-perks-index-tsx" */),
  "component---src-templates-about-us-template-about-us-template-tsx": () => import("./../../../src/templates/about-us-template/AboutUsTemplate.tsx" /* webpackChunkName: "component---src-templates-about-us-template-about-us-template-tsx" */),
  "component---src-templates-article-page-template-article-page-template-tsx": () => import("./../../../src/templates/article-page-template/ArticlePageTemplate.tsx" /* webpackChunkName: "component---src-templates-article-page-template-article-page-template-tsx" */),
  "component---src-templates-base-page-template-base-page-template-tsx": () => import("./../../../src/templates/base-page-template/BasePageTemplate.tsx" /* webpackChunkName: "component---src-templates-base-page-template-base-page-template-tsx" */),
  "component---src-templates-blog-post-template-blog-post-template-tsx": () => import("./../../../src/templates/blog-post-template/BlogPostTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-blog-post-template-tsx" */),
  "component---src-templates-event-index-tsx": () => import("./../../../src/templates/event/index.tsx" /* webpackChunkName: "component---src-templates-event-index-tsx" */),
  "component---src-templates-faq-template-faq-template-tsx": () => import("./../../../src/templates/faq-template/FAQTemplate.tsx" /* webpackChunkName: "component---src-templates-faq-template-faq-template-tsx" */),
  "component---src-templates-legal-block-story-template-legal-block-story-template-tsx": () => import("./../../../src/templates/legal-block-story-template/LegalBlockStoryTemplate.tsx" /* webpackChunkName: "component---src-templates-legal-block-story-template-legal-block-story-template-tsx" */),
  "component---src-templates-perk-template-perk-template-tsx": () => import("./../../../src/templates/perk-template/PerkTemplate.tsx" /* webpackChunkName: "component---src-templates-perk-template-perk-template-tsx" */)
}

