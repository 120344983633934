import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Script, ScriptStrategy } from "gatsby";
import classNames from "classnames";
import { CookieBanner } from "@app/components/common/cookie-banner/CookieBanner";
import { Modals } from "@app/components/common/modals/Modals";
import { Header, Footer } from "@app/components/common";
import { NotificationBar } from "@app/components/common/notification-bar/NotificationBar";
import { useGenericData } from "@app/hooks/use-generic-data/useGenericData";
import { useViewVariant } from "@app/hooks/use-view-variant/useViewVariant";
import {
  CookieConsentProvider,
  DownloadAppTopbarProvider,
  ModalProvider,
  ViewVariantProvider,
} from "@app/context";
import { SEO } from "@app/components";

export const Layout = (props) => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_V3_KEY}>
    <CookieConsentProvider>
      <DownloadAppTopbarProvider>
        <ModalProvider>
          <ViewVariantProvider>
            <InnerLayout {...props} />
          </ViewVariantProvider>
        </ModalProvider>
      </DownloadAppTopbarProvider>
    </CookieConsentProvider>
  </GoogleReCaptchaProvider>
);

const InnerLayout = ({ children, pageContext, location }) => {
  const genericData = useGenericData();
  const { isContentOnly } = useViewVariant();
  const showFooter = !location.pathname.includes("/wealth-management-advice");

  return (
    <>
      {process.env.GATSBY_GOOGLE_TAG_MANAGER_ID && (
        <>
          <Script strategy={ScriptStrategy.idle}>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.GATSBY_GOOGLE_TAG_MANAGER_ID}');`}
          </Script>
        </>
      )}
      {!isContentOnly && (
        <>
          <Script
            id="alpian-chatbot"
            strategy={ScriptStrategy.idle}
          >{`window.embeddedChatbotConfig = {chatbotId: "Lq8H-xYC0ujMv7dBjXr_Z", domain: "www.chatbase.co"}`}</Script>
          <Script
            id="Lq8H-xYC0ujMv7dBjXr_Z"
            src="https://www.chatbase.co/embed.min.js"
            defer={true}
            strategy={ScriptStrategy.idle}
          />
          <Script id="sleeknoteScript" strategy={ScriptStrategy.idle}>
            {`(function () {
              var sleeknoteScriptTag = document.createElement("script");
              sleeknoteScriptTag.type = "text/javascript";
              sleeknoteScriptTag.charset = "utf-8";
              sleeknoteScriptTag.src = ("//sleeknotecustomerscripts.sleeknote.com/171436.js");
              var s = document.getElementById("sleeknoteScript");
              s.parentNode.insertBefore(sleeknoteScriptTag, s);
            })();`}
          </Script>
        </>
      )}
      <SEO {...pageContext.seo} />
      <div className="scrollbar-thumb-rounded-sm flex min-h-screen flex-col justify-between scrollbar scrollbar-track-transparent scrollbar-thumb-base-400">
        {genericData?.notificationBar.isEnabled && !isContentOnly ? (
          <NotificationBar {...genericData.notificationBar} />
        ) : null}
        {!isContentOnly && <Header />}
        <MDXProvider components={{}}>
          <main className={classNames("duration-300")}>{children}</main>
        </MDXProvider>
        {!isContentOnly && <CookieBanner />}
        {!isContentOnly && showFooter && <Footer />}
        <Modals />
      </div>
    </>
  );
};

export default Layout;
