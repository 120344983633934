export const createHmacSignature = async () => {
  const secretKey = process.env.GATSBY_API_SECRET;

  if (!secretKey) throw new Error("API secret is missing");

  const timestamp = Date.now().toString();
  const message = `${timestamp}:${secretKey}`;

  const encoder = new TextEncoder();
  const key = await crypto.subtle.importKey(
    "raw",
    encoder.encode(secretKey),
    { name: "HMAC", hash: "SHA-256" },
    false,
    ["sign"],
  );

  const signature = await crypto.subtle.sign(
    "HMAC",
    key,
    encoder.encode(message),
  );

  return {
    timestamp,
    signature: btoa(
      String.fromCharCode(...Array.from(new Uint8Array(signature))),
    ),
  };
};
