import { API_PATHS } from "@app/constants/api";
import { createHmacSignature } from "@app/helpers/auth";
import { GenericFormMeta } from "@app/types/forms";
import { ShewealthContactFormData } from "./types";

export const useContact = () => {
  const sendContactRequest = async (
    data: ShewealthContactFormData,
    meta: GenericFormMeta,
  ) => {
    try {
      const { timestamp, signature } = await createHmacSignature();
      const response = await fetch(API_PATHS.INTERNAL.CONTACT_SIMPLE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Timestamp": timestamp,
          "X-Signature": signature,
        },
        body: JSON.stringify({
          formId: meta.formId,
          firstname: data.firstName,
          lastname: data.lastName,
          email: data.email,
          rt_action: meta.action,
          rt: meta.token,
          location: meta.location,
          title: meta.title,
          language: meta.language,
        }),
      });

      if (!response.ok) {
        throw new Error("Internal error");
      }

      return response;
    } catch (error) {
      throw new Error("Internal error");
    }
  };

  return { sendContactRequest };
};
