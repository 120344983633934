import React, { FC } from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { INotificationBar } from "@app/models/storyblok/datasource";
import { Link } from "../link/Link";
import { Marquee } from "./components/marquee/Marquee";

export const NotificationBar: FC<INotificationBar> = ({
  text,
  backgroundColor,
  link,
}) => {
  const { language } = useI18next();
  const labels = new Array(4).fill(text[language]);

  return (
    <div
      style={{
        backgroundColor: backgroundColor,
      }}
      className="group relative z-[1000] cursor-pointer overflow-hidden bg-primary-content"
    >
      <Link
        to={link}
        className="flex pb-2 pt-1.5 text-regular font-medium italic no-underline"
      >
        <Marquee
          data={labels}
          className="animate-marquee -animation-delay-120s"
        />
        <Marquee
          data={labels}
          className="animate-marquee-next -animation-delay-60s"
        />
      </Link>
    </div>
  );
};
