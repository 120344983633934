import React, { FC, useRef } from "react";
import classNames from "classnames";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";
import { Close } from "@app/components/common/icons";
import { Dialog } from "@app/constants/ui";
import { ModalState } from "@app/enums/modal";
import { useOutsideClick, useModal } from "@app/hooks";
import { useQRCodeData } from "./useQRCodeData";
import { GetLinkForm } from "./components/get-link-form/GetLinkForm";

export const DownloadApp: FC = () => {
  const { t } = useTranslation("download-app-modal");
  const { onChangeModalState } = useModal();
  const ref = useRef<HTMLDivElement>(null);
  const qr = useQRCodeData();

  const onClose = () => {
    onChangeModalState(ModalState.IDLE);
  };

  useOutsideClick(ref, onClose);

  return (
    <dialog id={Dialog.DOWNLOAD_APP} className="modal modal-open z-[1000]">
      <div
        className={classNames(
          "modal-box relative flex flex-col items-center rounded-lg bg-dark px-5 pb-10 pt-15 text-center text-primary-content",
          "scrollbar-thumb-rounded overflow-auto scrollbar scrollbar-thin scrollbar-track-transparent scrollbar-thumb-base-400",
          "md:px-[70px] md:pb-25 md:pt-22",
          "xl:w-full xl:max-w-[600px]",
        )}
        ref={ref}
      >
        <h3 className={classNames("mb-10 text-large", "xl:text-xx-large")}>
          {t("title")}
        </h3>
        <p className="mb-5 text-regular">{t("scan_qr")}</p>
        <QRCode
          bgColor="#00000000"
          fgColor="#ffffff"
          value={qr}
          className={classNames("mb-10 h-30 w-30 shrink-0")}
        />
        <p className="mb-5 text-regular">{t("get_link")}</p>
        <GetLinkForm />
        <Close
          className={classNames(
            "absolute right-5 top-5 h-5 w-5 cursor-pointer duration-300",
            "lg:right-6 lg:top-6 lg:h-6 lg:w-6",
            "hover:opacity-75",
          )}
          onClick={onClose}
        />
      </div>
    </dialog>
  );
};
