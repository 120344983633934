import { buildNetworkError } from "@app/helpers/api";
import { createHmacSignature } from "@app/helpers/auth";
import {
  AvailabilityItems,
  AvailabilityStaffTime,
  DailyAvailability,
  BookingBusinesses,
  Service,
  TimeSlot,
  ScheduleACallFormData,
  Recaptcha,
} from "@app/types/forms";
import {
  DEFAULT_TIME_INTERVAL,
  StuffAvailabilityStatuses,
} from "@app/constants";
import { formatToISOString, formatToDateTimeString } from "@app/formatters";
import {
  getEndTime,
  getEndOfDay,
  getStartOfDay,
  getTimeslots,
} from "@app/helpers";

export const getBusinesses = async (controller?: AbortController) => {
  const { timestamp, signature } = await createHmacSignature();
  const response = await fetch("/api/booking/businesses", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Timestamp": timestamp,
      "X-Signature": signature,
    },
    signal: controller.signal,
  });

  const parsedResponse = await response.json();

  if (!response.ok) {
    const error = new Error();

    if (parsedResponse?.errorName) {
      error.name = parsedResponse.errorName;
    }

    throw error;
  }

  const servicesToShow: string[] = JSON.parse(
    process.env.GATSBY_AVAILABLE_SERVICES_LIST ?? "[]",
  );

  return (parsedResponse as BookingBusinesses).value.filter((service) =>
    servicesToShow.includes(service.id),
  );
};

export const getGroupedStuffAvailabilityData = (data: AvailabilityItems[]) => {
  return data
    .map((employeeData) => {
      return {
        ...employeeData,
        availabilityItems: employeeData.availabilityItems.filter(
          (availability) =>
            availability.status === StuffAvailabilityStatuses.Available,
        ),
      };
    })
    .reduce((days: DailyAvailability[], day) => {
      let employesData = [...days];

      for (const timeRangeData of day.availabilityItems) {
        const date = getStartOfDay(timeRangeData.startDateTime.dateTime);

        if (
          employesData.some((dayAvailability) =>
            date.isSame(dayAvailability.date),
          )
        ) {
          const dataByDate = employesData.filter((daylyAvailability) =>
            date.isSame(daylyAvailability.date),
          )[0];

          if (
            dataByDate.data.some(
              (staffAvailability) => staffAvailability.staffId === day.staffId,
            )
          ) {
            employesData = [
              ...employesData.filter(
                (dayAvailability) =>
                  !dayAvailability.date.isSame(dataByDate.date),
              ),
              {
                ...dataByDate,
                data: [
                  ...dataByDate.data.filter(
                    (staffAvailability) =>
                      staffAvailability.staffId !== day.staffId,
                  ),
                  {
                    availabilityItems: [
                      ...dataByDate.data.filter(
                        (staffAvailability) =>
                          staffAvailability.staffId === day.staffId,
                      )[0].availabilityItems,
                      timeRangeData,
                    ],
                    staffId: day.staffId,
                  },
                ],
              },
            ];
          } else {
            employesData = [
              ...employesData.filter(
                (dayAvailability) =>
                  !dayAvailability.date.isSame(dataByDate.date),
              ),
              {
                ...dataByDate,
                data: [
                  ...dataByDate.data,
                  {
                    availabilityItems: [timeRangeData],
                    staffId: day.staffId,
                  },
                ],
              },
            ];
          }
        } else {
          employesData = [
            ...employesData,
            {
              date: date,
              data: [
                {
                  availabilityItems: [timeRangeData],
                  staffId: day.staffId,
                },
              ],
            },
          ];
        }
      }

      return employesData;
    }, []);
};

export const getStaffAvailability = async (
  startDate: string,
  staffMemberIds: string[],
  controller?: AbortController,
) => {
  const { timestamp, signature } = await createHmacSignature();
  const response = await fetch("/api/booking/staff-availability", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Timestamp": timestamp,
      "X-Signature": signature,
    },
    body: JSON.stringify({
      startDate: formatToDateTimeString(getStartOfDay(startDate)),
      endDate: formatToDateTimeString(getEndOfDay(startDate).add(30, "days")),
      staffMemberIds: staffMemberIds,
    }),
    signal: controller?.signal,
  });

  if (!response.ok) {
    throw new Error();
  }

  return getGroupedStuffAvailabilityData(
    ((await response.json()) as AvailabilityStaffTime).value,
  );
};

export const getAvailableTimeslots = async (
  availabilityData: AvailabilityItems[],
  service: Service,
) => {
  return getTimeslots(availabilityData, service);
};

export const getStaffIdForTimeslot = async (
  timeslot: TimeSlot,
  service: Service,
  recaptchaData: Recaptcha,
) => {
  const { timestamp, signature } = await createHmacSignature();
  const response = await fetch("/api/booking/staff-availability", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Timestamp": timestamp,
      "X-Signature": signature,
    },
    body: JSON.stringify({
      startDate: formatToDateTimeString(timeslot.time),
      endDate: formatToDateTimeString(
        getEndTime(
          timeslot.time,
          service.defaultDuration ?? DEFAULT_TIME_INTERVAL,
        ),
      ),
      staffMemberIds: timeslot.staffIds,
      recaptchaToken: recaptchaData.token,
      recaptchaAction: recaptchaData.action,
      recaptchaVersion: recaptchaData.version,
    }),
  });

  const parsedResponse = await response.json();

  if (!response.ok) {
    const error = new Error();

    if (parsedResponse?.errorName) {
      error.name = parsedResponse.errorName;
    }

    throw error;
  }

  const AvailableStaffIds = (parsedResponse as AvailabilityStaffTime).value
    .filter((person) =>
      person.availabilityItems.some(
        (slot) =>
          slot.startDateTime.dateTime ===
            formatToDateTimeString(timeslot.time) &&
          slot.status === StuffAvailabilityStatuses.Available,
      ),
    )
    .map((staff) => staff.staffId);

  if (!AvailableStaffIds.length) {
    throw new Error("No available staff for timeslot.");
  }

  return AvailableStaffIds[
    Math.floor(Math.random() * AvailableStaffIds.length)
  ];
};

export const bookAppointment = async (
  formId: string,
  lang: string,
  location: string,
  pageTitle: string,
  recaptchaData: Recaptcha[],
  { ...props }: ScheduleACallFormData,
) => {
  const staffId = await getStaffIdForTimeslot(
    props.timeslot,
    props.service,
    recaptchaData[0],
  );
  const { timestamp, signature } = await createHmacSignature();

  const bookingResult = await fetch("/api/booking/book-appointment", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Timestamp": timestamp,
      "X-Signature": signature,
    },
    body: JSON.stringify({
      formId: formId,
      lang: lang,
      location: location,
      pageTitle: pageTitle,
      ms_staff_id: staffId,
      serviceId: props.service.id,
      service_name: props.service.displayName,
      firstname: props.name,
      lastname: props.lastName,
      email: props.email,
      phoneCode: props.phoneCode.code,
      phone: props.phone,
      message: props.note,
      how_did_you_hear_about_us: props.howDidYouHear,
      startDateTime: props.timeslot.time.format(),
      endDateTime: formatToISOString(
        getEndTime(
          props.timeslot.time,
          props.service.defaultDuration ?? DEFAULT_TIME_INTERVAL,
        ),
      ),
      isSwissResident: props.isSwissResident,
      isTermsAccepted: props.isTermsAccepted,
      recaptchaToken: recaptchaData[1].token,
      recaptchaAction: recaptchaData[1].action,
      recaptchaVersion: recaptchaData[1].version,
    }),
  });

  await buildNetworkError(bookingResult);
};

export const useBooking = () => ({
  bookAppointment,
  getAvailableTimeslots,
  getBusinesses,
  getStaffAvailability,
  getGroupedStuffAvailabilityData,
  getStaffIdForTimeslot,
});
