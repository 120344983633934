import { TFunction } from "react-i18next";
import * as yup from "yup";
import { FIELDS_MAX_LENGTH } from "@app/constants/form-validation";

export const useValidationSchema = (t: TFunction<"translation", string>) =>
  yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .max(FIELDS_MAX_LENGTH.FIRST_NAME)
      .required(t("invalid-field")),
    lastName: yup
      .string()
      .trim()
      .max(FIELDS_MAX_LENGTH.LAST_NAME)
      .required(t("invalid-field")),
    email: yup
      .string()
      .trim()
      .required(t("invalid-field"))
      .email(t("invalid-email")),
    isSwissResidentConfirmed: yup.boolean().required().oneOf([true]),
    isPrivacyPolicyConfirmed: yup.boolean().required().oneOf([true]),
  });
