import React, { FC } from "react";
import classNames from "classnames";
import { useFormContext, useController } from "react-hook-form";
import { Spinner } from "@app/components/common/icons";
import { Timeslots, TimeSlot } from "@app/types/forms";
import { getStartOfDay } from "@app/helpers";
import { formatToShortLocalTimeString } from "@app/formatters";

type Props = {
  name: string;
  hint: string;
  timeSlots: Timeslots[];
  isLoading: boolean;
  isDisabled?: boolean;
};

export const TimeslotsList: FC<Props> = ({
  name,
  hint,
  timeSlots,
  isLoading,
  isDisabled,
}) => {
  const { control, formState, watch } = useFormContext();
  const { field } = useController({
    name,
    control,
  });
  const selectedDate = watch("date");

  const onChangeValue = (value: TimeSlot) => {
    if (!isDisabled && !formState.isSubmitting) {
      field.onChange(value);
    }
  };

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLElement>,
    value: TimeSlot,
  ) => {
    if (event.key === "Enter") {
      onChangeValue(value);
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center py-5">
          <Spinner className="h-6 w-6 stroke-primary-content" />
        </div>
      ) : selectedDate ? (
        <div className="flex flex-wrap gap-2.5">
          {timeSlots
            ?.filter((slots) =>
              slots.date.isSame(getStartOfDay(selectedDate)),
            )[0]
            ?.slots.map((slot) => (
              <button
                key={formatToShortLocalTimeString(slot.time)}
                tabIndex={0}
                type="button"
                onClick={() => onChangeValue(slot)}
                onKeyDown={(event) => onKeyDown(event, slot)}
                className={classNames(
                  "rounded-sm border px-4 py-2.5 duration-300",
                  isDisabled || formState.isSubmitting
                    ? "cursor-not-allowed opacity-50"
                    : "cursor-pointer",
                  field.value?.time.isSame(slot.time)
                    ? "border-primary-content focus:shadow-md"
                    : "border-divider",
                )}
                onBlur={field.onBlur}
                disabled={isDisabled || formState.isSubmitting}
              >
                <div
                  className={classNames(
                    "whitespace-nowrap text-extra-small duration-300",
                    "xl:text-regular",
                    field.value?.time.isSame(slot.time)
                      ? "text-primary-content"
                      : "text-base-400",
                  )}
                >
                  {formatToShortLocalTimeString(slot.time)}
                </div>
              </button>
            ))}
        </div>
      ) : (
        <p className="text-regular text-base-400">{hint}</p>
      )}
      <div className="label relative p-0">
        {formState.errors[name] ? (
          <p
            role="alert"
            className={classNames(
              "label-text-alt absolute top-1 text-extra-small text-error",
            )}
          >
            {formState.errors[name]?.message.toString()}
          </p>
        ) : null}
      </div>
    </div>
  );
};
