import React, { FC } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Trans, useTranslation } from "react-i18next";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Checkbox, Input } from "@app/components/common/form";
import { Button } from "@app/components/common";
import { ModalState } from "@app/enums/modal";
import { useModal } from "@app/hooks";
import { ShewealthContactFormData } from "./types";
import { useValidationSchema } from "./useValidationSchema";
import { useContact } from "./useContact";
import { RECAPTCHA_ACTION, INITIAL_VALUES, UNDEFINED_VALUE } from "./constants";

const formId = "34882fc2-30af-46a6-b077-74f117c20b49";

export const ContactForm: FC = () => {
  const { t } = useTranslation("shewealth-contact-form");
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { language } = useI18next();
  const { sendContactRequest } = useContact();
  const { onChangeModalState } = useModal();

  const validationSchema = useValidationSchema(t);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: INITIAL_VALUES,
  });

  const onSubmit = async (values: ShewealthContactFormData) => {
    try {
      const recaptchaToken = await executeRecaptcha(RECAPTCHA_ACTION);

      await sendContactRequest(values, {
        action: RECAPTCHA_ACTION,
        token: recaptchaToken,
        location: window?.location?.href ?? UNDEFINED_VALUE,
        title: document?.title ?? UNDEFINED_VALUE,
        language: language,
        formId: formId,
      });
      onChangeModalState(ModalState.SUCCESS);
    } catch (error) {
      onChangeModalState(ModalState.ERROR);
    } finally {
      methods.reset();
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="space-y-5 [&_a:hover]:text-primary-content"
      >
        <Input name="firstName" label={`${t("first_name")} *`} />
        <Input name="lastName" label={`${t("last_name")} *`} />
        <Input name="email" label={`${t("email")} *`} type="email" />
        <div className="space-y-2.5 text-start">
          <Checkbox name="isSwissResidentConfirmed" label={t("swiss_agree")} />
          <Checkbox
            name="isPrivacyPolicyConfirmed"
            label={
              <Trans
                t={t}
                i18nKey="agree"
                components={[
                  <a
                    key="privacy-policy"
                    href="/legal/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  />,
                  <a
                    key="smartpurse-privacy-policy"
                    href="https://smartpurse.me/en-gb/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  />,
                ]}
              />
            }
          />
        </div>
        <div className="flex flex-row justify-center gap-x-3 pt-5">
          <Button
            label={t("form_submit")}
            isDisabled={methods.formState.isSubmitting}
            isLoading={methods.formState.isSubmitting}
            type="submit"
          />
        </div>
      </form>
    </FormProvider>
  );
};
