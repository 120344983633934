import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Category } from "@app/types/blog";

const DEFAULT_ORDER_VALUE = 99;

export const useBlogCategories = (): Category[] => {
  const { language } = useI18next();
  const { categories } = useStaticQuery(graphql`
    {
      categories: allStoryblokDatasourceEntry(
        filter: {
          data_source: { eq: "blog-categories" }
          data_source_dimension: { ne: null }
        }
      ) {
        group(field: { value: SELECT }) {
          edges {
            node {
              value
              optionKey: data_source_dimension
              option: dimension_value
            }
          }
        }
      }
    }
  `);

  return categories.group
    .map((item) => ({
      uuid: item.edges[0].node.value,
      name: item.edges.filter((edge) => edge.node.optionKey === language)[0]
        ?.node.option,
      order:
        item.edges.filter((edge) => edge.node.optionKey === "order")[0]?.node
          .option ?? DEFAULT_ORDER_VALUE,
    }))
    .sort((a: Category, b: Category) => a.order - b.order);
};
