import React, { FC } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "@app/components/common/link/Link";
import { IRichText, IRichTextNode } from "@app/models/storyblok/basic";
import { NUMERIC_HEADER_LEVEL_MAP } from "../../constants";
import { extractHeadings, getTextFromRichText } from "../../utils";

type Props = {
  content: IRichText<IRichTextNode>;
};

export const ContentNavigation: FC<Props> = ({ content }) => {
  const { t } = useTranslation("article");
  const headingsArray = extractHeadings(content).map((heading) => ({
    anchor: `#${heading.attrs?.id}`,
    level: heading.attrs?.level,
    text: getTextFromRichText(heading.content),
  }));

  return (
    <div className={classNames("my-15 w-full space-y-2.5")}>
      <h2 className="text-medium-standart">{t("table_of_contents")}</h2>
      <ul className={classNames("space-y-2.5")}>
        {headingsArray.map((heading) => (
          <li
            key={heading.anchor}
            className={classNames({
              "ml-5 xl:ml-9": heading.level === NUMERIC_HEADER_LEVEL_MAP.h2,
              "ml-10 xl:ml-18": heading.level === NUMERIC_HEADER_LEVEL_MAP.h3,
              "ml-15 xl:ml-27": heading.level === NUMERIC_HEADER_LEVEL_MAP.h4,
              "ml-20 xl:ml-36": heading.level > NUMERIC_HEADER_LEVEL_MAP.h4,
            })}
          >
            <Link
              to={heading.anchor}
              className={classNames(
                "text-base-400 duration-300 hover:text-primary-content",
              )}
            >
              {heading.text}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
