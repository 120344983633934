import React, { FC } from "react";
import classNames from "classnames";
import { Fade } from "react-awesome-reveal";
import { DEFAULT_FADE_DURATION } from "@app/constants";

type Props = {
  title: string;
  subtitle?: string;
};

export const Hero: FC<Props> = ({ title, subtitle }) => (
  <div
    className={classNames(
      "px-5 pt-15 text-primary-content",
      "lg:px-16 lg:pt-25",
      "xl:-mt-20 xl:px-24 xl:pt-60",
      "xxl:mx-auto xxl:max-w-container xxl:px-0",
    )}
  >
    <div
      className={classNames(
        "space-y-5",
        "md:max-w-[73%]",
        "xl:mx-auto xl:max-w-[66%] xl:text-center",
      )}
    >
      <Fade duration={DEFAULT_FADE_DURATION} triggerOnce>
        <h1
          className={classNames(
            "text-large",
            "lg:text-xx-large",
            "xl:text-huge",
          )}
        >
          {title}
        </h1>
      </Fade>
      {subtitle ? (
        <Fade duration={DEFAULT_FADE_DURATION} triggerOnce>
          <div
            className={classNames(
              "text-regular text-base-400",
              "xl:text-medium",
            )}
          >
            {subtitle}
          </div>
        </Fade>
      ) : null}
    </div>
  </div>
);
