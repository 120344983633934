export const buildNetworkError = async (response: Response) => {
  const parsedResponse = await response.json();

  if (!response.ok) {
    const error = new Error();

    if (parsedResponse?.errorName) {
      error.name = parsedResponse.errorName;
    }

    throw error;
  }
};
